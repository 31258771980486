<template>
    <div id="research">
        <div class="content">
            <div class="introduction">
                <!--<div class="bg">
                    <img :src="require('@/assets/images/research/banner_bg.png')" alt="">
                </div>-->
                <div class="search">
                    <h2>研学成绩查询</h2>
                    <div class="search-bar">
                        <el-select v-model="type" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input
                            v-model="licenseNum"
                            placeholder="请输入证件号码"
                            @keyup.enter.native="checkLicenseNum"
                            suffix-icon="el-icon-search">
                        </el-input>
                        <a @click = "checkLicenseNum" href = "javascript:void(0);" class = "btn" >立即查询</a>
                    </div>
                </div>
                <div class="intr" v-if="data">
                    <h2 class="topic">{{ isEn ? data.yxljj.enName : data.yxljj.cnName }}</h2>
                    <p v-html="isEn ? data.yxljj.data.enContent : data.yxljj.data.cnContent"></p>
                </div>
            </div>
            <div class="information" v-if="data">
                <h2>{{ isEn ? data.yxldt.enName : data.yxldt.cnName }}</h2>
                <ul>
                    <li v-for="(item,index) in data.yxldt.data" :key="index">
                        <h4>{{ isEn ? item.enTitle : item.cnTitle }}</h4>
                        <div class="img">
                            <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" />
                            <img :src="require('../../assets/images/article_bg.png')" alt="" class="bg">
                        </div>
                        <p v-html="isEn ? item.enContentSummary : item.cnContentSummary"></p>
                        <i class="icon" @click="toDetail(item)"></i>
                    </li>
                </ul>
                <router-link :to="data.yxldt.templateUrl" class="btn-more">{{ $t('lang.see_more') }}</router-link>
            </div>
        </div>

        <div class="dialog" :style="{'display': showDialog ? 'block': 'none'}">
            <div :class="['detail-box', 'animate__animated', dialogVisible ? 'animate__fadeInUp' : 'animate__fadeOutDown']">
                <i class="icon-close" @click="closeDialog"></i>
                <div class="search-result-bg">
                    <img :src="require('@/assets/images/research/bg_result.png')" alt="">
                </div>
                <div class="search-result">
                    <p>学员姓名</p>
                    <span class="student-name" v-if="classInfo.length > 0">{{ classInfo[0].name }}</span>
                    <ul v-if="classInfo.length > 0">
                        <li v-for="(item, index) in classInfo" :key="index">
                            <div class="lf">
                                <p>{{ item.school }} - {{ item.grade }}</p>
                                <span>第{{ item.activityNumber }}期 {{ item.theme }}</span>
                            </div>
                            <div class="result" @click="downloadCertificate(item)">下载证书</div>
                            <!--<div :class="['result', item.testResult ? 'icon-succ' : 'icon-fail']">
                                <div>{{ item.testResult ? '合格' : '不合格' }}</div>
                            </div>-->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="canvas">
            <canvas ref="myCanvas" width="1350px" height="1002px"></canvas>
        </div>
    </div>
</template>
<script>
    import {urls, staticPath} from '@/lib/common'
    export default {
        name: "Research",
        components: {},
        data() {
            return {
                staticPath,
                options: [
                    {
                        label: '身份证',
                        value: '身份证'
                    }, {
                        label: '护照',
                        value: '护照'
                    }, {
                        label: '港澳通行证',
                        value: '港澳通行证'
                    }, {
                        label: '其他',
                        value: '其他'
                    }
                ],
                type: '身份证',
                licenseNum: '',
                data: '',
                showDialog: false,
                dialogVisible: false,
                isEn: false,
                classInfo: [],
                classInfoIndex: null,
                ctx: null,
                canvasW: 1350,
                canvasH: 1002,
                
            };
        },
        watch: {
            '$i18n.locale': function (val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            getTemplate() {
                this.$http.get(urls.getResearchTemp + this.navId).then(res => {
                    if (res.data.status === 200) {
                        this.data = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            checkLicenseNum() {
                let params = {
                    idcardNumber: this.licenseNum,
                    idcardType: this.type
                }
                this.$http.get(urls.research, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.classInfo = res.data.data;
                        this.showDialog = true;
                        this.dialogVisible = true;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            closeDialog() {
                this.dialogVisible = false;
                setTimeout(() => {0
                    this.showDialog = false;
                }, 400)
            },
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const articleId = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'ActivityDetail',
                        params: {
                            id: articleId
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            },
            downloadCertificate(item) {
                this.writeCertificateInfo(item)
            },
            drawCanvasBg() {
                const self = this;
                self.myCanvas = self.$refs.myCanvas;
                if (self.myCanvas.getContext) {
                    self.ctx = self.myCanvas.getContext("2d");
                    let img = new Image();
                    img.src = require('../../assets/images/research/certificate.png');
                    img.style.width = self.canvasW + "px";
                    img.style.height = self.canvasH + "px";
                    img.onload = function() {
                        self.ctx.drawImage(img,0,0);
                    };
                }
            },
            writeCertificateInfo(item) {
                let fdate = null, fy, fm;
                let ldate = null, ly, lm, ld;
                // 填入内容偏移位置参数
                const nameAlignH = 400,
                    firstYearAlignW = 340,
                    firstMonthAlignW = 480,
                    firstDateAlignH = 475,
                    lastYearAlignW = 900,
                    lastMonthAlignW = 1015,
                    lastDateAlignW = 1090,
                    lastDateAlignH = 865;
                let nameAlignW = 215 - (item.name.length - 1) * 15;
                if (item.firstDate) {
                    fdate = new Date(item.firstDate);
                    fy = fdate.getFullYear();
                    fm = fdate.getMonth() + 1;
                }
                if (item.lastDate) {
                    ldate = new Date(item.lastDate);
                    ly = ldate.getFullYear();
                    lm = ldate.getMonth() + 1;
                    ld = ldate.getDate();
                }
                this.ctx.font = "32px Arial";
                this.ctx.textBaseline = "middle";
                this.ctx.fillText(item.name, nameAlignW, nameAlignH);
                this.ctx.fillText(fy, firstYearAlignW, firstDateAlignH);
                this.ctx.fillText(fm, firstMonthAlignW, firstDateAlignH);
                
                this.ctx.fillText(ly, lastYearAlignW, lastDateAlignH);
                this.ctx.fillText(lm, lastMonthAlignW, lastDateAlignH);
                this.ctx.fillText(ld, String(ld).length === 1 ? lastDateAlignW + 10 : lastDateAlignW, lastDateAlignH);
                
                // 下载部分
                let a = document.createElement('a');
                let image = this.$refs.myCanvas.toDataURL("image/png");
                a.download = item.name + '研学证书';
                a.href = image;
                a.click();
                // 清空画布
                this.ctx.clearRect(0, 0, this.canvasW, this.canvasH);
                this.ctx = null;
                // 重绘背景
                this.drawCanvasBg();
            }
        },
        mounted() {
            this.navId = decodeURIComponent(atob(this.$route.query.n));
            this.getTemplate();
            this.drawCanvasBg()
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #research {
        @{deep} .navbar {
            position: relative;
        }
        .content {
            background: #F2FCFF url("../../assets/images/education/research_bg.png") no-repeat center center;
            background-size: 100% 100%;
            .btn-more {
                display: block;
                width: 220px;
                height: 70px;
                line-height: 70px;
                margin: 2% auto 5%;
                font-size: 20px;
                border-radius: 50px;
                text-align: center;
                background: linear-gradient(to left top, #dc000c, #ff4b7c);
                color: #fff;
                box-sizing: border-box;
                box-shadow: 0 20px 30px rgba(247, 144, 170, 0.49);
            }
            .introduction {
                /*background: url("../../assets/images/research/research_bg.png") no-repeat center center;*/
                /*background-size: 100% 100%;*/
                .bg {
                    padding-right: 10vw;
                    width: 1680px;
                }
                .search {
                    width: 1280px;
                    margin: 0 auto;
                    background: #EDEEF5;
                    padding: 1vw 6vw 2vw;
                    border-radius: 8px;
                    box-shadow: 0px 0px 10px #edeef5;
                    >h2 {
                        font-size: 28px;
                        text-align: center;
                        margin: 1vw 0;
                        color: #353968;
                    }
                    @{deep} .search-bar {
                        display: flex;
                        align-items: center;
                        .el-select {
                            margin-right: 10px;
                        }
                        .el-input__inner {
                            border-radius: 50px;
                        }
                        .btn{display: none;}
                    }
                }
                .intr {
                    width: 1280px;
                    margin: 2vw auto;
                    h2 {
                        font-size: 28px;
                        margin-bottom: 2vw;
                        color:#353968;
                    }
                    p {
                        font-size: 18px;
                        text-indent: 4px;
                        line-height: 36px;
                    }
                }
            }
            .information {
                width: 1280px;
                margin: 2vw auto;
                h2 {
                    font-size: 28px;
                    margin: 2vw 0;
                    color: #353968;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        width: 22%;
                        background: #fff;
                        line-height: 24px;
                        margin: 0 4% 4% 0;
                        box-shadow: 0 10px 40px rgba(0, 181, 255, 0.08);
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                        .img {
                            position: relative;
                            width: 282px;
                            height: 190px;
                            overflow: hidden;
                        }
                        h4 {
                            color: #6b6667;
                            padding: 5px 15px;
                            font-size: 14px;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        >p {
                            height: 44px;
                            margin: 5px 15px 0;
                            font-size: 12px;
                            line-height: 22px;
                            color: #aaaaaa;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        >span {
                            display: inline-block;
                            width: 100%;
                            margin: 10px auto;
                            text-align: center;
                        }
                        .icon {
                            display: inline-block;
                            width: 1.45vw;
                            height: 1.07vw;
                            background: url("../../assets/images/icon_arrow.png") no-repeat 100%
                            100%;
                            background-size: contain;
                            cursor: pointer;
                            margin: 10px 20px 20px;
                        }
                    }
                }
            }
        }
        .dialog {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,.7);
            z-index: 10003;
            .detail-box {
                width: 30%;
                margin: 6% auto 0;
                background-size: 100% 100%;
                position: relative;
                text-align: center;
                .icon-close {
                    position: absolute;
                    right: -1vw;
                    top: -1vw;
                    width: 2vw;
                    height: 2vw;
                    cursor: pointer;
                    background: url("../../assets/images/products/icon_close.png") no-repeat center center;
                    background-size: 100%;
                }
                .search-result {
                    position: absolute;
                    left: 0;
                    top: 26.8%;
                    width: 100%;
                    >h3 {
                        font-size: 1.562vw;
                        color: #fff;
                    }
                    >p {
                        margin-top: 6vw;
                        font-size: 1.04vw;
                        color: #625F6F;
                    }
                    .student-name {
                        display: inline-block;
                        font-size: 3.125vw;
                        color: #DC5141;
                        margin: 10px 0;
                    }
                    ul {
                        width: 66%;
                        margin: auto;
                        height: 18vw;
                        overflow-y: auto;
                        border-top: 2px dashed #F1D78D;
                        &::-webkit-scrollbar {
                            width: 4px;
                            height: 4px;
                            background-color: rgba(0, 0, 0, 0.3);
                        }
                        &::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            border-radius: 10px;
                            background-color: #F5F5F5;
                        }
                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                            background-color: #555;
                        }
                            li {
                            text-align: left;
                            margin-top: 1vw;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .lf {
                                line-height: 180%;
                                width: 80%;
                                p {
                                    font-size: 0.9375vw;
                                    color: #17A9B1;
                                }
                                span {
                                    font-size: 0.73vw;
                                    color: #625F6F;
                                }
                            }
                            .result {
                                width: 68px;
                                height: 42px;
                                line-height: 42px;
                                color: #DC000C;
                                font-size: 14px;
                                /*background: url("../assets/images/research/icon_border_succ.png") no-repeat center center;*/
                                /*background-size: 100%;*/
                                text-align: center;
                                cursor: pointer;
                                &.icon-fail {
                                    color: #625F6F;
                                    background-image: url("../../assets/images/research/icon_border_fail.png");
                                }
                            }
                        }
                    }
                }
            }
        }
        .canvas {
            width: 1350px;
            height: 1002px;
            position: absolute;
            left: -2000px;
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #research{
            .content{
                .btn-more{
                    .fontFn(14);
                    .fontFn(140,width);
                    .fontFn(44,height);
                    .fontFn(44,line-height);
                }
                .introduction{
                    background:none;

                    .bg{
                        width:100%;
                        padding-right: 0;
                        background:url('../../assets/images/phone/module_bg7.png') no-repeat right bottom;
                        background-size: auto 100%;
                        padding-bottom: 4%;
                    }

                    .search{
                        width: 94.8%;
                        padding-bottom: 8%;
                        box-shadow: 0px 10px 20px #EDEEF5;
                        margin-bottom: 12%;
                        h2{
                            .fontFn(18);
                            .fontFn(66,height);
                            .fontFn(66,line-height);
                        }
                        .search-bar{
                            display: block;
                            .el-select{
                                width: 100%;
                                margin-right: 0;
                                margin-bottom: 6%;
                            }
                            .el-input__inner{
                                border-color: #dcdfe6;
                            }
                            .btn{
                                margin-top: 6%;
                                display: block;
                                background-color: #D93C45;
                                font-size: 16px;
                                height: 40px;
                                line-height: 40px;
                                color:#fff;
                                text-align: center;
                                border-radius: 20px;
                            }
                        }
                    }

                    .intr{
                        margin-bottom: 10%;
                        background:url('../../assets/images/phone/module_bg8.png') no-repeat center top;
                        background-size: 118%;
                        width: 100%;
                        h2{
                            .fontFn(18);
                            padding: 0 5.2%;
                            color: #353968;
                        }
                        p{
                            .fontFn(14);
                            color:#343750;
                            padding: 0 5.2%;
                            .fontFn(2,text-indent);
                        }
                    }


                }
                .information{
                    width: 100%;
                    h2{
                        .fontFn(18);
                        color: #353968;
                        padding: 0 2.6% 2%;
                    }
                    ul{
                        display: block;
                        font-size: 0;
                        white-space: nowrap;
                        overflow-x: auto;
                        padding: 0 10px;

                        li{
                            display: inline-block;
                            vertical-align: top;
                            width: 85.3%;
                            margin-right: 10px;
                            .fontFn(375,min-height);
                            &>p{
                                white-space: normal;
                            }
                            h4{
                                color:#6B6667;
                                .fontFn(16);
                                white-space: normal;
                            }
                            .icon{
                                .fontFn(27,width);
                                .fontFn(18,height);
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            .dialog{
                z-index: 1505000;
                .detail-box{
                    width:99%;
                    margin-top: 10%;
                    .icon-close{
                        width: 36px;
                        height: 36px;
                        top:auto;
                        bottom:-8%;
                        right: auto;
                        left:50%;
                        margin-left: -18px;
                    }
                    .search-result{
                        &>h3{
                            .fontFn(18);
                        }
                        &>p{
                            .fontFn(15);
                            margin-top:14%;
                        }
                        .student-name{
                            .fontFn(46);
                        }
                        ul{
                            .fontFn(220,height);
                            li{
                                padding: 2% 0;
                                .lf{
                                    p{
                                        .fontFn(14);
                                    }
                                    span{
                                        .fontFn(12);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }   
    }
</style>